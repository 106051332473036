// import React, { useEffect } from "react";
// import { Button, Alert, Row, Col } from "react-bootstrap";
// import { Navigate, Link, useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useTranslation } from "react-i18next";
// import classNames from "classnames";

// // actions
// import { resetAuth, loginUser } from "../../redux/actions";

// // store
// import { RootState, AppDispatch } from "../../redux/store";

// // components
// import { VerticalForm, FormInput } from "../../components/";

// import AuthLayout from "./AuthLayout";

// interface UserData {
//   username: string;
//   password: string;
// }

// /* bottom links */
// const BottomLink = () => {
//   const { t } = useTranslation();

//   return (
//     <Row className="mt-3">
//       <Col className="text-center">
//         <p>
//           <Link to={"/auth/forget-password"} className="text-white-50 ms-1">
//             {t("Forgot your password?")}
//           </Link>
//         </p>
//         <p className="text-white-50">
//           {t("Don't have an account?")}{" "}
//           <Link to={"/auth/register"} className="text-white ms-1">
//             <b>{t("Sign Up")}</b>
//           </Link>
//         </p>
//       </Col>
//     </Row>
//   );
// };

// /* social links */
// const SocialLinks = () => {
//   const socialLinks = [
//     {
//       variant: "primary",
//       icon: "facebook",
//     },
//     {
//       variant: "danger",
//       icon: "google",
//     },
//     {
//       variant: "info",
//       icon: "twitter",
//     },
//     {
//       variant: "secondary",
//       icon: "github",
//     },
//   ];
//   return (
//     <>
//       <ul className="social-list list-inline mt-3 mb-0">
//         {(socialLinks || []).map((item, index: number) => {
//           return (
//             <li key={index} className="list-inline-item">
//               <Link
//                 to="#"
//                 className={classNames(
//                   "social-list-item",
//                   "border-" + item.variant,
//                   "text-" + item.variant
//                 )}
//               >
//                 <i className={classNames("mdi", "mdi-" + item.icon)}></i>
//               </Link>
//             </li>
//           );
//         })}
//       </ul>
//     </>
//   );
// };

// const Login = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch<AppDispatch>();

//   const { user, userLoggedIn, loading, error } = useSelector(
//     (state: RootState) => ({
//       user: state.Auth.user,
//       loading: state.Auth.loading,
//       error: state.Auth.error,
//       userLoggedIn: state.Auth.userLoggedIn,
//     })
//   );

//   useEffect(() => {
//     dispatch(resetAuth());
//   }, [dispatch]);

//   /*
//   form validation schema
//   */
//   const schemaResolver = yupResolver(
//     yup.object().shape({
//       username: yup.string().required(t("Please enter Username")),
//       password: yup.string().required(t("Please enter Password")),
//     })
//   );

//   /*
//   handle form submission
//   */
//   const onSubmit = (formData: UserData) => {
//     dispatch(loginUser(formData["username"], formData["password"]));
//   };

//   const location = useLocation();
//   const redirectUrl = location.state?.from || "/";

//   return (
//     <>
//       {(userLoggedIn || user) && <Navigate to={redirectUrl}></Navigate>}

//       <AuthLayout
//         helpText={t(
//           "Enter your email address and password to access admin panel."
//         )}
//         bottomLinks={<BottomLink />}
//       >
//         {error && (
//           <Alert variant="danger" className="my-2">
//             {error}
//           </Alert>
//         )}

//         <VerticalForm<UserData>
//           onSubmit={onSubmit}
//           resolver={schemaResolver}
//           defaultValues={{ username: "test", password: "test" }}
//         >
//           <FormInput
//             label={t("Username")}
//             type="text"
//             name="username"
//             placeholder="Enter your Username"
//             containerClass={"mb-3"}
//           />
//           <FormInput
//             label={t("Password")}
//             type="password"
//             name="password"
//             placeholder="Enter your password"
//             containerClass={"mb-3"}
//           ></FormInput>

//           <div className="text-center d-grid">
//             <Button variant="primary" type="submit" disabled={loading}>
//               {t("Log In")}
//             </Button>
//           </div>
//         </VerticalForm>

//         <div className="text-center">
//           <h5 className="mt-3 text-muted">{t("Sign in with")}</h5>
//           <SocialLinks />
//         </div>
//       </AuthLayout>
//     </>
//   );
// };

// export default Login;


//Code for implemeting Azure authentication

// import React, { useEffect, useState } from "react";
// import { Button, Alert, Row, Col, Form } from "react-bootstrap";
// import { Navigate, Link, useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";
// import { useMsal } from "@azure/msal-react";
// import { InteractionType } from "@azure/msal-browser";
// import classNames from "classnames";

// // actions
// import { resetAuth } from "../../redux/actions";

// // store
// import { RootState, AppDispatch } from "../../redux/store";

// // components
// import AuthLayout from "./AuthLayout";

// import { useUser } from "../../userContext"; // Use the UserContext

// interface LoginFormState {
//   email: string;
//   password: string; // Not actually used, but can be for UI consistency
// }

// const BottomLink = () => {
//   const { t } = useTranslation();
//   return (
//     <Row className="mt-3">
//       <Col className="text-center">
//         <p>
//           <Link to={"/auth/forget-password"} className="text-white-50 ms-1">
//             {t("Forgot your password?")}
//           </Link>
//         </p>
//         <p className="text-white-50">
//           {t("Don't have an account?")}{" "}
//           <Link to={"/auth/register"} className="text-white ms-1">
//             <b>{t("Sign Up")}</b>
//           </Link>
//         </p>
//       </Col>
//     </Row>
//   );
// };

// /* social links */
// const SocialLinks = () => {
//   const socialLinks = [
//     {
//       variant: "primary",
//       icon: "facebook",
//     },
//     {
//       variant: "danger",
//       icon: "google",
//     },
//     {
//       variant: "info",
//       icon: "twitter",
//     },
//     {
//       variant: "secondary",
//       icon: "github",
//     },
//   ];
//   return (
//     <>
//       <ul className="social-list list-inline mt-3 mb-0">
//         {(socialLinks || []).map((item, index: number) => {
//           return (
//             <li key={index} className="list-inline-item">
//               <Link
//                 to="#"
//                 className={classNames(
//                   "social-list-item",
//                   "border-" + item.variant,
//                   "text-" + item.variant
//                 )}
//               >
//                 <i className={classNames("mdi", "mdi-" + item.icon)}></i>
//               </Link>
//             </li>
//           );
//         })}
//       </ul>
//     </>
//   );
// };

// const Login = () => {
//   const { t } = useTranslation();
//   const { instance } = useMsal();
//   const { setIsLoggingIn } = useUser(); // Get the setter for isLoggingIn
//   const location = useLocation();
//   const redirectUrl = location.state?.from || "/";

//   const { user, userLoggedIn, error } = useSelector(
//         (state: RootState) => ({
//           user: state.Auth.user,
//           error: state.Auth.error,
//           userLoggedIn: state.Auth.userLoggedIn,
//         })
//       );

//   const [loginForm, setLoginForm] = useState<LoginFormState>({
//     email: "",
//     password: ""
//   });

//   const [loginError, setLoginError] = useState<string | null>(null);

//   const handleLogin = async () => {
//     try {
//       setIsLoggingIn(true); // Indicate that login is in process
//       await instance.loginRedirect({
//         scopes: ["openid", "profile", "User.Read"],
//         loginHint: loginForm.email, // Use the email input as loginHint
//       });
//     } catch (error) {
//       console.error("Login error:", error);
//       setIsLoggingIn(false); // Reset if login fails
//       setLoginError("Failed to initiate login. Please try again.");
//     }
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setLoginForm({ ...loginForm, [name]: value });
//   };

//   return (
//     <>
//     {(userLoggedIn || user) && <Navigate to={redirectUrl}></Navigate>}
//     <AuthLayout
//       helpText={t("Enter your email address to access the admin panel.")}
//       bottomLinks={<BottomLink />}
//     >
//       {loginError && (
//         <Alert variant="danger" className="my-2">
//           {loginError}
//         </Alert>
//       )}
//       <Form>
//         <Form.Group controlId="formEmail">
//           <Form.Label>{t("Email address")}</Form.Label>
//           <Form.Control
//             type="email"
//             name="email"
//             value={loginForm.email}
//             onChange={handleInputChange}
//             placeholder={t("Enter your email")}
//           />
//         </Form.Group>
//         <Form.Group controlId="formPassword">
//           <Form.Label>{t("Password")}</Form.Label>
//           <Form.Control
//             type="password"
//             name="password"

//             value={loginForm.password}
//             onChange={handleInputChange}
//             placeholder={t("Enter your password")}
//             disabled // Disabling since password isn't used for authentication
//           />
//         </Form.Group>
//         <div className="text-center d-grid mt-4">
//           <Button variant="primary" onClick={handleLogin}>
//             {t("Log In")}
//           </Button>
//         </div>
//       </Form>
//     </AuthLayout>
//     </>
//   );
// };

// export default Login;

//Code for implementing Keycloak authentication
import React, { useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import { useKeycloak } from "../../keycloakContext";
import { Navigate, useLocation } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();
  // const redirectUrl = location.state?.from || "/"; // Default redirect to home if no state
  const redirectUrl = location.state?.from || window.location.pathname;

  const [loginError, setLoginError] = useState<string | null>(null);

  const handleLogin = async () => {
    try {
      if (keycloak && initialized) {
        await keycloak.login(); // Simplified login call
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginError("Failed to initiate login. Please try again.");
    }
  };

  // If Keycloak is not yet initialized, show a loading state
  if (!initialized) {
    return <div>Loading...</div>;
  }

  // If the user is already authenticated, redirect to the appropriate page
  if (keycloak.authenticated) {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <>
      {loginError && (
        <Alert variant="danger" className="my-2">
          {loginError}
        </Alert>
      )}
      <AuthLayout helpText={t("Enter your email address to access the admin panel.")}>
        <Form>
          <Form.Group controlId="formEmail">
            <Form.Label>{t("Email address")}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder={t("Enter your email")}
              disabled // Disable since email is not used for Keycloak authentication
            />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>{t("Password")}</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder={t("Enter your password")}
              disabled // Disable since password is not used for Keycloak authentication
            />
          </Form.Group>
          <div className="text-center d-grid mt-4">
            <Button variant="primary" onClick={handleLogin}>
              {t("Log In with Keycloak")}
            </Button>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};

export default Login;