// import React from "react";
// import { Route, Navigate, RouteProps } from "react-router-dom";

// import { APICore } from "../helpers/api/apiCore";

// /**
//  * Private Route forces the authorization before the route can be accessed
//  * @param {*} param0
//  * @returns
//  */
// const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
//   const api = new APICore();

//   return (
//     <Route
//       {...rest}
//       render={(props: RouteProps) => {
//         if (api.isUserAuthenticated() === false) {
//           // not logged in so redirect to login page with the return url
//           return (
//             <Navigate
//               // state={from: props['path']}
//               to={{
//                 pathname: "/auth/login",
//                 // state: { from: props['path'] },
//               }}
//             />
//           );
//         }

//         const loggedInUser = api.getLoggedInUser();

//         // check if route is restricted by role
//         if (roles && roles.indexOf(loggedInUser.role) === -1) {
//           // role not authorised so redirect to login page
//           return <Navigate to={{ pathname: "/" }} />;
//         }
//         // authorised so return component
//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default PrivateRoute;


// import React from "react";
// import { Route, Navigate, RouteProps, useLocation } from "react-router-dom";
// import { APICore } from "../helpers/api/apiCore";

// const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
//   const api = new APICore();
//   const location = useLocation();

//   return (
//     <Route
//       {...rest}
//       render={(props: RouteProps) => {
//         if (api.isUserAuthenticated() === false) {
//           return (
//             <Navigate
//               to="/auth/login"
//               state={{ from: location.pathname }}
//             />
//           );
//         }

//         const loggedInUser = api.getLoggedInUser();

//         if (roles && roles.indexOf(loggedInUser.role) === -1) {
//           return <Navigate to="/" />;
//         }

//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default PrivateRoute;


// Code for implemeting Azure authentication

// import React from "react";
// import { Route, Navigate, RouteProps, useLocation } from "react-router-dom";
// import { useIsAuthenticated } from "@azure/msal-react";

// const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
//   const isAuthenticated = useIsAuthenticated();
//   const location = useLocation();

//   return (
//     <Route
//       {...rest}
//       render={(props: RouteProps) => {
//         if (!isAuthenticated) {
//           return (
//             <Navigate
//               to="/auth/login"
//               state={{ from: location.pathname }}
//             />
//           );
//         }

//         // Add role-based access control if needed
//         // const loggedInUser = api.getLoggedInUser();
//         // if (roles && roles.indexOf(loggedInUser.role) === -1) {
//         //   return <Navigate to="/" />;
//         // }

//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default PrivateRoute;


//Code for implementing Keycloak authentication
import React from "react";
import { Route, Navigate, RouteProps, useLocation } from "react-router-dom";
import { useKeycloak } from '../keycloakContext'; // Import Keycloak context

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const { isAuthenticated } = useKeycloak();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (!isAuthenticated) {
          return (
            <Navigate
              to="/auth/login"
              state={{ from: location.pathname }}
            />
          );
        }

        // Add role-based access control if needed
        // const loggedInUser = api.getLoggedInUser();
        // if (roles && roles.indexOf(loggedInUser.role) === -1) {
        //   return <Navigate to="/" />;
        // }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;

