import React, { createContext, useContext, useEffect, ReactNode, useState } from 'react';
import Keycloak from 'keycloak-js'; // Use KeycloakInstance type
import keycloakInstance from './keycloak'; // Ensure this exports your Keycloak instance
import { Spinner } from 'react-bootstrap';

interface KeycloakContextType {
  keycloak: Keycloak;
  initialized: boolean;
  isAuthenticated: boolean;
  username: string | null;
  name: string | null;
  profilePhotoUrl: string | null;
}

const KeycloakContext = createContext<KeycloakContextType | undefined>(undefined);

export const useKeycloak = (): KeycloakContextType => {
  const context = useContext(KeycloakContext);
  if (!context) {
    throw new Error('useKeycloak must be used within a KeycloakProvider');
  }
  return context;

};

export const KeycloakProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [keycloakInstanceState, setKeycloakInstance] = useState<Keycloak>(keycloakInstance);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null);

  useEffect(() => {
    const initKeycloak = async () => {
      try {
        const currentUrl = window.location.href;
        const authenticated = await keycloakInstanceState.init({
          onLoad: 'check-sso', // or 'login-required' depending on your flow
          silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
          redirectUri: currentUrl,
        });

        if (authenticated) {
          const userProfile = await keycloakInstanceState.loadUserProfile();
          setUsername(userProfile.username ?? null); 
          const fullName = `${userProfile.firstName ?? ''} ${userProfile.lastName ?? ''}`.trim();
          setName(fullName.length > 0 ? fullName : null);
          // setProfilePhotoUrl(userProfile.photoUrl ?? null); 
        }

        setIsAuthenticated(authenticated);
        setInitialized(true);
      } catch (error) {
        console.error('Failed to initialize Keycloak:', error);
      }
    };

    initKeycloak();
  }, [keycloakInstanceState]);

  return (
    <KeycloakContext.Provider value={{ keycloak: keycloakInstanceState, initialized, isAuthenticated, username, name, profilePhotoUrl }}>
      {initialized ? children :  <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="grow" variant="primary" className="display-1" />
      </div>}
    </KeycloakContext.Provider>
  );
};

