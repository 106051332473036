// Main code
// import React from "react";
// import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

// // layout constants
// import { LayoutTypes } from "../constants/layout";

// // strore
// import { RootState } from "../redux/store";

// // All layouts containers
// import DefaultLayout from "../layouts/Default";
// import VerticalLayout from "../layouts/Vertical";
// import DetachedLayout from "../layouts/Detached";
// import HorizontalLayout from "../layouts/Horizontal/";
// import TwoColumnLayout from "../layouts/TwoColumn/";

// import {
//   authProtectedFlattenRoutes,
//   publicProtectedFlattenRoutes,
// } from "./index";
// import { APICore } from "../helpers/api/apiCore";

// interface IRoutesProps {}

// const AllRoutes = (props: IRoutesProps) => {
//   const { layout } = useSelector((state: RootState) => ({
//     layout: state.Layout,
//   }));
//     const location = useLocation();
//   const getLayout = () => {
//     let layoutCls = TwoColumnLayout;

//     switch (layout.layoutType) {
//       case LayoutTypes.LAYOUT_HORIZONTAL:
//         layoutCls = HorizontalLayout;
//         break;
//       case LayoutTypes.LAYOUT_DETACHED:
//         layoutCls = DetachedLayout;
//         break;
//       case LayoutTypes.LAYOUT_VERTICAL:
//         layoutCls = VerticalLayout;
//         break;
//       default:
//         layoutCls = TwoColumnLayout;
//         break;
//     }
//     return layoutCls;
//   };

//   let Layout = getLayout();
//   const api = new APICore();

//   return (
//     <React.Fragment>
//       <Routes>
//         <Route>
//           {publicProtectedFlattenRoutes.map((route, idx) => (
//             <Route
//               path={route.path}
//               element={
//                 <DefaultLayout {...props} layout={layout}>
//                   {route.element}
//                 </DefaultLayout>
//               }
//               key={idx}
//             />
//           ))}
//         </Route>

//         <Route>
//           {authProtectedFlattenRoutes.map((route, idx) => (
//             <Route
//               path={route.path}
//               element={
//                 api.isUserAuthenticated() === false ? (
//                   <Navigate
//                     to= "/auth/login"
//                       // hash:route.path,
//                       state={{ from: location.pathname }}
                   
//                   />
//                 ) : (
//                   <Layout {...props}>{route.element}</Layout>
//                 )
//               }
//               key={idx}
//             />
//           ))}
//         </Route>
//       </Routes>
//     </React.Fragment>
//   );
// };

// export default AllRoutes;

// import React from "react";
// import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { LayoutTypes } from "../constants/layout";
// import { RootState } from "../redux/store";
// import DefaultLayout from "../layouts/Default";
// import VerticalLayout from "../layouts/Vertical";
// import DetachedLayout from "../layouts/Detached";
// import HorizontalLayout from "../layouts/Horizontal/";
// import TwoColumnLayout from "../layouts/TwoColumn/";
// import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from "./index";
// import { APICore } from "../helpers/api/apiCore";

// interface IRoutesProps {}

// const AllRoutes = (props: IRoutesProps) => {
//   const { layout } = useSelector((state: RootState) => ({
//     layout: state.Layout,
//   }));
//   const location = useLocation();

//   const getLayout = () => {
//     let layoutCls = TwoColumnLayout;

//     switch (layout.layoutType) {
//       case LayoutTypes.LAYOUT_HORIZONTAL:
//         layoutCls = HorizontalLayout;
//         break;
//       case LayoutTypes.LAYOUT_DETACHED:
//         layoutCls = DetachedLayout;
//         break;
//       case LayoutTypes.LAYOUT_VERTICAL:
//         layoutCls = VerticalLayout;
//         break;
//       default:
//         layoutCls = TwoColumnLayout;
//         break;
//     }
//     return layoutCls;
//   };

//   let Layout = getLayout();
//   const api = new APICore();

//   return (
//     <React.Fragment>
//       <Routes>
//         <Route>
//           {publicProtectedFlattenRoutes.map((route, idx) => (
//             <Route
//               path={route.path}
//               element={
//                 <DefaultLayout {...props} layout={layout}>
//                   {route.element}
//                 </DefaultLayout>
//               }
//               key={idx}
//             />
//           ))}
//         </Route>

//         <Route>
//           {authProtectedFlattenRoutes.map((route, idx) => (
//             <Route
//               path={route.path}
//               element={
//                 api.isUserAuthenticated() === false ? (
//                   <Navigate
//                     to="/auth/login"
//                     state={{ from: location.pathname }}
//                   />
//                 ) : (
//                   <Layout {...props}>{route.element}</Layout>
//                 )
//               }
//               key={idx}
//             />
//           ))}
//         </Route>
//       </Routes>
//     </React.Fragment>
//   );
// };

// export default AllRoutes;

//Code for implemeting Azure authetication

// import React from "react";
// import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { useIsAuthenticated } from "@azure/msal-react";

// // layout constants
// import { LayoutTypes } from "../constants/layout";

// // store
// import { RootState } from "../redux/store";

// // All layouts containers
// import DefaultLayout from "../layouts/Default";
// import VerticalLayout from "../layouts/Vertical";
// import DetachedLayout from "../layouts/Detached";
// import HorizontalLayout from "../layouts/Horizontal/";
// import TwoColumnLayout from "../layouts/TwoColumn/";

// import {
//   authProtectedFlattenRoutes,
//   publicProtectedFlattenRoutes,
// } from "./index";

// interface IRoutesProps {}

// const AllRoutes = (props: IRoutesProps) => {
//   const { layout } = useSelector((state: RootState) => ({
//     layout: state.Layout,
//   }));
//   const location = useLocation();
//   const isAuthenticated = useIsAuthenticated();

//   const getLayout = () => {
//     let layoutCls = TwoColumnLayout;

//     switch (layout.layoutType) {
//       case LayoutTypes.LAYOUT_HORIZONTAL:
//         layoutCls = HorizontalLayout;
//         break;
//       case LayoutTypes.LAYOUT_DETACHED:
//         layoutCls = DetachedLayout;
//         break;
//       case LayoutTypes.LAYOUT_VERTICAL:
//         layoutCls = VerticalLayout;
//         break;
//       default:
//         layoutCls = TwoColumnLayout;
//         break;
//     }
//     return layoutCls;
//   };

//   let Layout = getLayout();

//   return (
//     <React.Fragment>
//       <Routes>
//         <Route>
//           {publicProtectedFlattenRoutes.map((route, idx) => (
//             <Route
//               path={route.path}
//               element={
//                 <DefaultLayout {...props} layout={layout}>
//                   {route.element}
//                 </DefaultLayout>
//               }
//               key={idx}
//             />
//           ))}
//         </Route>

//         <Route>
//           {authProtectedFlattenRoutes.map((route, idx) => (
//             <Route
//               path={route.path}
//               element={
//                 !isAuthenticated ? (
//                   <Navigate
//                     to="/auth/login"
//                     state={{ from: location.pathname }}
//                   />
//                 ) : (
//                   <Layout {...props}>{route.element}</Layout>
//                 )
//               }
//               key={idx}
//             />
//           ))}
//         </Route>
//       </Routes>
//     </React.Fragment>
//   );
// };

// export default AllRoutes;


//Code for implemeting Keycloak authentication

import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// layout constants
import { LayoutTypes } from "../constants/layout";

// store
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import TwoColumnLayout from "../layouts/TwoColumn/";

// Routes
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from "./index";

// Keycloak context
import { useKeycloak } from "../keycloakContext";  // Assuming keycloakContext is in this path

interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));
  const location = useLocation();
  const { keycloak, initialized } = useKeycloak();  // Use Keycloak context

  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();

  // Render loading state until Keycloak is initialized
  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                !keycloak.authenticated ? ( // Check Keycloak authentication
                  <Navigate to="/auth/login" state={{ from: location.pathname }} />
                ) : (
                  <Layout {...props}>{route.element}</Layout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;